
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import DocWidgetList from '@simple-payments/shared-packages/components/Doc/WidgetList/DocWidgetList.vue'
import GibddForm from './GibddForm.vue'

@Component({
  components: {
    GibddForm,
    DocWidgetList
  }
})
export default class extends Vue {
  @Prop() subTitle!: string
  @Prop() title!: string
  @Prop() canBack!: boolean
  @Prop({
    type: Boolean,
    default: true
  }) showTitle!: boolean
}
