
import { Component, Vue, Prop, Watch, Ref } from 'nuxt-property-decorator'
import LazyHydrate from 'vue-lazy-hydration'
import { Events } from '@simple-payments/shared-packages/types/enum/Events'
import SearchFormCtc from '~/components/search-form/SearchFormCtc.vue'
import { ErrorValidation } from '~/types/ErrorValidation'
import SearchFormGrz from '~/components/search-form/SearchFormGrz.vue'

@Component({
  components: {
    SearchFormGrz,
    LazyHydrate,
    SearchFormCtc,
    SearchFormVu: () => import('~/components/search-form/SearchFormVu.vue'),
    SearchFormUin: () => import('~/components/search-form/SearchFormUin.vue')
  }
})
export default class extends Vue {
  @Prop() value!: string
  @Prop() isIframe!: boolean
  @Prop() leadSourceId!: string
  @Ref() searchBlock!: HTMLElement

  tabIndex: number = 1
  dataErrorValidations: any = {}
  errorDocVal: string = ''
  allowServices = this.$allowServices()

  @Watch('value', { deep: true })
  onChangeValue () {
    this.tabIndex = 1
  }

  @Watch('allowServices', { deep: true })
  allowServicesHandler () {
    if (!this.allowServices.ctc_by_grz && this.tabIndex === 1) {
      this.tabIndex = 2
    }
    return this.allowServices
  }

  mounted () {
    this.$eventBus.$on(Events.DATA_ERROR_FROM_VALIDATION, (error) => {
      this.dataErrorValidations = error
      this.errorDocVal = this.dataErrorValidations.error.response.data.error.document_value[0]
    })

    if (!this.$allowServices().ctc_by_grz) {
      this.tabIndex = 2
    }
    const newTab = parseInt(this.$route.hash.slice(1), 10)
    if (!isNaN(newTab)) {
      this.tabIndex = newTab
    }
  }

  @Watch('$route.hash')
  routeChange () {
    const newTab = parseInt(this.$route.hash.slice(1), 10)
    if (!isNaN(newTab)) {
      this.tabIndex = newTab
      this.$nextTick(() => {
        this.searchBlock.scrollIntoView({ behavior: 'smooth' })
      })
    }
  }

  getErrorFormValidations (type: string): {} | ErrorValidation {
    if (process.client && this.errorDocVal) {
      return type === this.dataErrorValidations.payerData[0].document_type
        ? {
            isError: true,
            msgError: this.errorDocVal
          }
        : {
            isError: false,
            msgError: ''
          }
    }

    return {
      isError: false,
      msgError: ''
    }
  }
}
